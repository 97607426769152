/// CONFIG ///

const CONFIG = {
    APP_TITLE:      'ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¹',
    API_IP: 		'62.109.20.146',
    API_DOMAIN: 	'https://vkusneev.ru/',
    API_C_DOMAIN: 	'https://vkusneev.ru',
    API_GATE: 		'https://vkusneev.ru/api.php',
    PHONE: 			'43543543534',
    MINIMAL: 		2000,
    CP_PUBLIC_ID: 	'sdfsdf',
    INSTAGRAM_URL:  'https://www.instagram.com/sdfsdfdsfsd/',
    PHONE: 			'+7 995 000 55 11'
};

export default CONFIG;