import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class BrickCatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PREPARED_CATS: []
        };

        this.LOADING = false;
        this.LOADED = {};
    }

    renderRows = () =>
    {
        if (this.props.CATS) {
            let RENDERED_CATS = [];

            for (let i = 0; i < this.props.CATS.length; i++) {
                let CAT = this.props.CATS[i];

                if (i < 2) {
                    RENDERED_CATS.push(
                        <div className="preview-col-6" key={i}>
                            <Link to={"/" + CAT.url} className="cat_preview big" style={{backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image + ')'}}>
                                <span>{CAT.title}</span>
                            </Link>
                        </div>
                    )
                } else {
                    RENDERED_CATS.push(
                        <div className="preview-col-4" key={i}>
                            <Link to={"/" + CAT.url} className="cat_preview small" style={{backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image + ')'}}>
                                <span>{CAT.title}</span>
                            </Link>
                        </div>
                    )
                }
                
            }
            
            return RENDERED_CATS;
        }
    }

    render()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="cats-preview">
                        {this.renderRows()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(BrickCatPreview);