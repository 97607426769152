import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class SocialNetworks extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }

	render() {
		let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/zen.png';
		return;
    	return (
    		<div className="social-networks">
    			<a rel="nofollow" href="http://vk.com/public168682506" target="_blank">
    				<i className="fab fa-vk"></i>
				</a>
				<a rel="nofollow" href="http://facebook.com/ecomarket.ru/" target="_blank">
					<i className="fab fa-facebook-f"></i>
				</a>
				<a rel="nofollow" href="https://instagram.com/Ecomarket.ru" target="_blank">
					<i className="fab fa-instagram"></i>
				</a>
				<a rel="nofollow" href="https://zen.yandex.ru/id/5d11d208e1551900b0ad9b81" target="_blank">
					<img className="soc_img" src={IMAGE_SRC} />
				</a>
			</div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(SocialNetworks)
