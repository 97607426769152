import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        DO_SEARCH: state.DO_SEARCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Contacts extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            TOKEN: props.TOKEN
        }
    }

    componentDidMount()
    {
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.match.params.url !== prevProps.match.params.url) {
            this.props.closeAllModals();
         
            this.setState({PAGE: false, URL: this.props.match.params.url, ERROR: false});
        }
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    checkRerender()
    {
    }


    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });

        DATA.push({
            'to' : '/company/contacts',
            'title' : 'ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    getYaKey()
    {
        let items = [
            '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
        ];

        var item = items[Math.floor(Math.random()*items.length)];

        return item;
    }

    recordPhoneClick = () =>
    {
        if (typeof window.yaCounter71028040 !== "undefined") {
            window.yaCounter71028040.reachGoal('CONTACTS_PHONE_CLICKED');
        }
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹</title>
                        <meta name="description" content="ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹"/>
                        <meta name="keywords" content="ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹" />
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="main-heading ">ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹</h1>
                                    <div className="contacts_row">
                                        <span>Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½: </span><a onClick={() => this.recordPhoneClick()} href={'tel: ' + CONFIG.PHONE}>{CONFIG.PHONE}</a>
                                    </div>
                                    <div className="contacts_row">
                                        <span>Email: </span><a href="mailto: info@vkusneev.ru">info@vkusneev.ru</a>
                                    </div>
                                    <div className="contacts_row">
                                        <span>ÐÐ´Ñ€ÐµÑ: </span>Ð³. ÐœÐ¾ÑÐºÐ²Ð°, ÐšÐ°Ð»ÑƒÐ¶ÑÐºÐ¾Ðµ ÑˆÐ¾ÑÑÐµ, 22-Ð¹ ÐºÐ¸Ð»Ð¾Ð¼ÐµÑ‚Ñ€,10
                                    </div>
                                    <YMaps 
                                        query={{
                                            apikey: this.getYaKey(),
                                            load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl'
                                        }}
                                    >
                                        <Map 
                                            className="contacts_map"
                                            defaultState={{
                                                center: [55.60036,37.47024], 
                                                zoom: 14,
                                                controls: ['zoomControl', 'fullscreenControl']
                                            }}
                                        >
                                            <Placemark geometry={[55.60036,37.47024]} options={{
                                                iconLayout: 'default#image',
                                                iconImageHref: '/system_images/location.png',
                                                iconImageSize: [40, 40],
                                                iconImageOffset: [-20, -20]
                                            }} />
                                            <SearchControl options={{ float: 'right', noPlacemark: true }} />
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
   
            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Contacts))
