import React, {Component} from 'react';
import {Switch, Route,withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { YMaps } from 'react-yandex-maps';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ScrollToTop from './../APP/HELPERS/scrolltotop.js';

/// PARTS ///
import HeaderBlock from './../APP/COMPONENTS/Header.js';
import ContentBlock from './../APP/COMPONENTS/Content.js';
import Preloader from './../APP/COMPONENTS/Preloader.js';

import ScrollTop from './../APP/COMPONENTS/ContentParts/ScrollTop.js';

import ScrollPreview from './../APP/COMPONENTS/ContentParts/ScrollPreview'
import Adverts from './../APP/COMPONENTS/ContentParts/Adverts'

import Product from './../APP/MODALS/Product.js';
import Cart from './../APP/MODALS/Cart.js';
import Lk from './../APP/MODALS/Lk.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function mapStateToProps(state, myProps) {
    return {
        PRODUCTS: state.PRODUCTS,
        POPULAR: state.POPULAR,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class App extends Component {

	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	componentDidMount()
	{
		this.parseGetRequests();
	}

	parseGetRequests()
	{
		this.checkPriceType();
		this.checkFriendsCode();
		this.checkOrderPay();
		this.checkBonuses();
	}

	getPtType(PT_DATA)
	{
		if (PT_DATA !== "undefined") {
			switch(PT_DATA) {
				case 'p1$%27':
					return 1;
				case 'p2$!29':
					return 2;
				case 'p3!@63':
					return 3;
				case 'p4*&49':
					return 4;
			}
		}

		return false;
	}

	checkPriceType()
	{
		let PT_DATA = this.get('pt');
		let PT = this.getPtType(PT_DATA);
		if (typeof PT_DATA !== "undefined" && PT) {
			cookies.set('PT_DATA', PT, { path: '/' });
			this.props.setPriceType(PT);
		} else {
			PT = cookies.get('PT_DATA');
			if ((PT !== null) && (typeof PT !== "undefined")) {
				cookies.set('PT_DATA', PT, { path: '/' });
				this.props.setPriceType(PT);
			}
		}
	}

	checkFriendsCode()
	{
		let FRIENDS = this.get('we_are_friends');
		if (typeof FRIENDS !== "undefined") {
			cookies.set('we_are_friends', 'TRUE', { path: '/' });
			this.props.setFriendsDiscount(true);
		} else {
			FRIENDS = cookies.get('we_are_friends');
			if ((FRIENDS !== null) && (typeof FRIENDS !== "undefined")) {
				cookies.set('we_are_friends', 'TRUE', { path: '/' });
				this.props.setFriendsDiscount(true);
			}
		}
	}

	checkBonuses()
	{
		let BONUSES = this.get('lk_bonuses');
		if (typeof BONUSES !== "undefined") {
			this.props.openLK('BONUSES');
		}
	}

	checkOrderPay()
	{
		let OrderId = this.get('pay_order');
		if (typeof OrderId !== "undefined") {
			this.props.setLoadOrder(OrderId);
			this.props.openLK('HISTORY');
		}
	}

	get(name){
	   if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
	      return decodeURIComponent(name[1]);
	}

	scrollToTop = () =>
	{
		window.scrollTo({top: 0, behavior: "smooth"})
	}

	getYaKey()
	{
		let items = [
			'24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
            '486bc32f-60f4-4320-9d61-c8d14935e017',
            'c0e4e3d2-913b-4873-a81a-59ea899446f8',
            '64f61949-4219-4c76-9c63-a76af46cb444',
            'a45fc414-5133-407b-a440-91d028095f30',
            'ddb478df-6120-46a8-8331-ea67cefec68c'
		];

		var item = items[Math.floor(Math.random()*items.length)];

		return item;
	}

	renderHomePageBlock = () =>
	{
		if ((this.props.history.location.pathname == '/') && (this.props.PRODUCTS)) {
			return (
				<>
					<Adverts />
					<ScrollPreview ID={0} />
				</>
			)
		}
	}

	render() {
		return (
			<div className="the-holder" ref={this.myRef}>
				<YMaps 
					query={{
						apikey: this.getYaKey()
					}}
				>
					<HeaderBlock />
					{this.renderHomePageBlock()}
					<ContentBlock />

					<ScrollTop scrollToTop={this.scrollToTop} />

					<ScrollToTop />
					<Preloader />
					<a target="_blank" className="wa-float" href="https://wa.me/79950005511"><img src="/system_images/social/whatsapp.png"/></a>
				</YMaps>
			</div>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(App))
