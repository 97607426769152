import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

/// PARTS ///
import Logo from './../../APP/COMPONENTS/HeaderParts/Logo';
import SearchBlock from './../../APP/COMPONENTS/HeaderParts/SearchBlock';
import MobileNavToggle from './../../APP/COMPONENTS/HeaderParts/MobileNavToggle';
import HeadCart from './../../APP/COMPONENTS/HeaderParts/HeadCart';
import Tabs from './../../APP/COMPONENTS/HeaderParts/Tabs';
import Tops from './../../APP/COMPONENTS/HeaderParts/Tops';
import TopNavs from './../../APP/COMPONENTS/HeaderParts/TopNavs';
import CatNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/CatNavs';
import MainNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/MainNavs';
import UserBlock from './../../APP/COMPONENTS/HeaderParts/NavBar/UserBlock';

import NavBar from './../../APP/COMPONENTS/HeaderParts/NavBar';
import MobileSearchBlock from './../../APP/COMPONENTS/HeaderParts/MobileSearchBlock';

function mapStateToProps(state, myProps) {
    return {
        SHOW_NAVS: state.SHOW_NAVS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;


class header extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
			SHOW_SEARCH: false,
			SHOW_NAV: false
        }
    }

	componentDidMount()
	{
		///window.addEventListener("scroll", this.toggleBodyClass);
	}

	componentWillUnmount()
	{
		///window.removeEventListener("scroll", this.toggleBodyClass);
	}

	toggleBodyClass = () => {
		/*
		if (window.scrollY >= 170) {
			document.body.classList.add("top_shall_float");
		} else {
			document.body.classList.remove("top_shall_float");
		}

		if (window.scrollY > 300) {
			document.body.classList.add("float_shown");
		} else {
			document.body.classList.remove("float_shown");
		}
		*/
	};

	showNavs = () =>
	{
		if (this.props.SHOW_NAVS) {
			this.props.showNavs(false);
		} else {
			this.props.closeAllModals(true);
			this.props.showNavs(true);
		}
	}

	getTogglerImage = () =>
	{
		if (this.props.SHOW_NAVS) {
			return <img src="/system_images/nav_toggler_close.png" />
		} else {
			return <img src="/system_images/nav_toggler.png" />
		}
	}

	hideNavs = () =>
	{
		this.setState({SHOW_NAV: false})
	}

	renderNavs = () =>
	{
		let CSS_CLASS = '';
		if (this.state.SHOW_NAV) {
			CSS_CLASS = ' visible';
		}

		return (
			<div className={"mobile_nav_holder " + CSS_CLASS}>
				<div className="mobile_nav_real_holder">
					<div className="mobile_navs_block">
						<CatNavs hideNavs={this.hideNavs}/>
						<div className="mobile_navs_separator"></div>
						<MainNavs hideNavs={this.hideNavs} />
						<a className="close-modal" onClick={() => this.toggleNav()}>
							<img src="/system_images/close.png" />
						</a>
					</div>
					<a onClick={() => this.hideNavs()} className="nav_close_layer"></a>
				</div>
			</div>
		)
	}

	renderSearch = () =>
	{
		let CSS_CLASS = '';
		if (this.state.SHOW_SEARCH) {
			CSS_CLASS = ' visible';
		}

		return (
			<div className={"mobile_search_holder " + CSS_CLASS}>
				<SearchBlock focus={this.state.SHOW_SEARCH} reset={this.state.RESET_SEARCH} />
				<Link to={'/'} className="search_resetter" onClick={() => this.resetSearch()}>ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ</Link>
			</div>
		)
	}

	resetSearch = () =>
	{
		this.setState({SHOW_SEARCH: false, RESET_SEARCH: new Date()});
	}

	toggleNav = () =>
	{
		this.setState({SHOW_NAV: !this.state.SHOW_NAV})
	}

	toggleSearch = () =>
	{
		this.setState({SHOW_SEARCH: !this.state.SHOW_SEARCH})
	}

	renderSearchButton = () =>
	{
		return (
			<a onClick={() => this.toggleSearch()}>
				<img src="/system_images/search.png" />
			</a>
		)
	}

	renderNavButton = () =>
	{
		return (
			<a onClick={() => this.toggleNav()}>
				<img src="/system_images/m_nav.png" />
			</a>
		)
	}

	render() {
		if (WIDTH > 1200) {
			return (
				<>
				<header className="header-wrap inner">
					<div className="widther">
						<section id="header_top">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<div className="head-flex">
											<Logo/>
											<div className="head_right">
												<SearchBlock />
												<HeadCart />
												<UserBlock />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>				
				</header>
				
				</>
			);
		} else {
			return (
				<>
				<header className="header-wrap inner">
					<div className="widther">
						<section id="header_top">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<div className="head-flex">
											<Logo/>
											<div className="head_right">
												<UserBlock />
												{this.renderSearchButton()}
												{this.renderNavButton()}
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{this.renderSearch()}
						{this.renderNavs()}
						<HeadCart />
					</div>				
				</header>
				</>
			);
		}
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(header)
