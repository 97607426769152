import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Done extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            ORDER_ID: props.ORDER,
            NUMBER: props.NUMBER,
            EMAIL: '',
            NO_FORM: false,
            SECURITY: props.SECURITY
        }
    }

    componentDidMount()
    {

    }

    openLK()
    {
        this.props.openOrder(false);
        this.props.openLK(true);
    }

    recordEmail = (text) => 
    {
        this.setState({EMAIL: text.target.value});
    }

    renderLowerBlock()
    {
        return;
        return (
            <div>
                <div className="insta_coins">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/coins.png'} />
                </div>
                <div className="insta_text">
                    Ð—Ð° ÐºÐ°Ð¶Ð´ÑƒÑŽ Ð²Ð°ÑˆÑƒ Ð¿Ð¾ÐºÑƒÐ¿ÐºÑƒ Ð² ECOMARKET.RU Ð¼Ñ‹ Ð½Ð°Ñ‡Ð¸ÑÐ»ÑÐµÐ¼ Ð²Ð°Ð¼ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ Ð½Ð° Ð²Ð°Ñˆ <a onClick={() => this.openLK()}>ÑÑ‡ÐµÑ‚ Ð² Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ðµ</a>
                </div>
                <div className="insta_green">
                    <img src={CONFIG.API_C_DOMAIN + '/imgs/insta_green.png'} />
                </div>
                <a target="_blank" href="https://www.instagram.com/ecomarket.ru/" className="insta_link">
                    ÐŸÐ¾Ð´Ð¿Ð¸ÑÑ‹Ð²Ð°Ð¹Ñ‚ÐµÑÑŒ Ð½Ð° Ð½Ð°Ñˆ Instagram,
                </a>
                <div className="insta_after">
                    ÑÐ»ÐµÐ´Ð¸Ñ‚Ðµ Ð·Ð° Ð½Ð¾Ð²Ð¾ÑÑ‚ÑÐ¼Ð¸, ÑƒÑ‡Ð°ÑÑ‚Ð²ÑƒÐ¹Ñ‚Ðµ Ð² ÐºÐ¾Ð½ÐºÑƒÑ€ÑÐ°Ñ… Ð¸ Ð²Ñ‹Ð¸Ð³Ñ€Ñ‹Ð²Ð°Ð¹Ñ‚Ðµ!
                </div>
            </div>
        );
    }

    sendToEmail = async () =>
    {
        try {
            let TOKEN = 'UNREGISTERED';
            if (this.props.USER) {
                TOKEN = this.props.USER.TOKEN;
            }

            let SEND_DATA = {
                action:     'sendOrderLetter',
                id:         this.state.ORDER_ID,
                email:      this.state.EMAIL,
                apikey:     TOKEN,
                security:   this.state.SECURITY,

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({NO_FORM: true})
            }
        } catch (error) {
            console.log('BAD_ORDER_DATA');
        }
    }

    renderEmailForm()
    {
        let SHOW = false;
        if (!this.props.USER) {
            SHOW = true;
        } else {
            if (this.props.USER.EMAIL.length < 1) {
                SHOW = true;
            }
        }

        if (SHOW) {
            if (!this.state.NO_FORM) {
                return (
                    <div className="mail-form">
                        <div className="flex">
                            <div className="order_form_input_holder email_holder">
                                <input type="email" className="order-input email" placeholder="mail@mail.ru" value={this.state.EMAIL}  onChange={(text) => this.recordEmail(text)} />
                            </div>
                            <div className="order_form_input_holder submit_holder">
                                <a className="orange-button" onClick={() => this.sendToEmail()}>
                                    ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ
                                </a>
                            </div>
                        </div>
                        <div className="form_letter_message">
                            Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ email Ð¸ Ð¼Ñ‹ Ð¿Ñ€Ð¸ÑˆÐ»Ñ‘Ð¼ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ Ð¾ Ð²Ð°ÑˆÐµÐ¼ Ð·Ð°ÐºÐ°Ð·Ðµ
                        </div>
                    </div>
                );
            }
        }
    }

    goToLk = () =>
    {
        this.props.openOrder(false);
        this.props.history.push('/personal');
    }

	render()
    {
        return (
            <div className="order_form_holder">
                <div className="modal_content">
                    <div className="order_finals_block flex_block">
                        <div className="done_head">
                            Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾! Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚
                        </div>
                        <div className="after_order_head">
                            ÐÐ¾Ð¼ÐµÑ€ Ð·Ð°ÐºÐ°Ð·Ð°<br/><span>{this.state.NUMBER}</span>
                        </div>
                        <div className="done_message">
                            Ð¡ Ð´ÐµÑ‚Ð°Ð»ÑÐ¼Ð¸ Ð·Ð°ÐºÐ°Ð·Ð°<br/>Ð¼Ð¾Ð¶Ð½Ð¾ Ð¾Ð·Ð½Ð°ÐºÐ¾Ð¼Ð¸Ñ‚ÑŒÑÑ<br/>Ð² <span onClick={() => this.goToLk()}>Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ðµ</span>
                        </div>
                        <div className="text-center">
                            <a className="orange-button slim" onClick={() => this.props.openOrder(false)}>ÐŸÐ¾Ð½ÑÑ‚Ð½Ð¾, ÑÐ¿Ð°ÑÐ¸Ð±Ð¾!</a>
                        </div>
                        {this.renderEmailForm()}
                    </div>
                </div>
            </div>
        )         
    }
}

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(Done));